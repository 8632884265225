body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: #ffffff; /* White background for the body */
  color: #000; /* Default text color (black) */
}

.ada-container {
  max-width: 800px;
  margin: 2rem auto;
  padding: 2rem;
  background-color: #ffffff; /* White background for the container */
  border-radius: 8px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  margin-top: 130px;
}

.ada-title {
  text-align: center;
  color:black; /* Primary brand color */
  font-size: 2.5rem; /* Larger font size for the title */
  margin-bottom: 1rem;
}

.ada-intro {
  text-align: center;
  font-size: 1.1rem;
  color: #333; /* Dark grey for better readability */
  margin-bottom: 1rem;
}

.ada-date {
  text-align: center;
  color: #777; /* Grey for the date */
  font-size: 0.9rem;
  margin-bottom: 1.5rem;
}

.ada-subtitle {
  color: black; /* Primary brand color for subtitles */
  font-size: 1.8rem; /* Slightly larger font size for subtitles */
  margin-top: 1.5rem;
}

.ada-section-title {
  font-size: 1.5rem; /* Larger font size for section titles */
  margin-top: 1.5rem;
  color: #333; /* Dark grey for section titles */
}

.ada-problem {
  margin: 1rem 0;
}

.ada-challenges {
  list-style-type: none; /* Remove bullet points */
  padding: 0;
  margin: 1rem 0;
}

.ada-challenges li {
  margin: 0.5rem 0;
}

.ada-features {
  list-style-type: none; /* Remove bullet points */
  padding: 0;
  margin: 1rem 0;
}

.ada-features li {
  margin: 0.5rem 0;
}

.ada-platform-features {
  font-weight: bold;
  margin-top: 1rem;
}
.ada-container {
  position: relative;
  padding: 20px;
}

.close-icon {
  position: absolute;
  top: 20px;
  right: 20px;
  margin-top: -30px;
}

.close-icon-img {
  width: 20px;
  height: 20px;
  cursor: pointer;
}
