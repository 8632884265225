/* ReadingList.css */

.reading-list-container {
    max-width: 800px; /* Limit the width of the container */
    margin: 0 auto; /* Center the container */
    padding: 20px; /* Add padding inside the container */
    font-family: Arial, sans-serif; /* Set a readable font */
    color: #333; /* Set a dark text color for contrast */
    background-color: #f9f9f9; /* Light background color for the container */
    border-radius: 8px; /* Rounded corners */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    margin-top: 100px;
    text-align: center;
  }
  
  .reading-list-title {
    font-size: 2.5em; /* Large title font size */
    margin-bottom: 15px; /* Space below the title */
    color: #2c3e50; /* Darker color for the title */
    text-align: center; /* Center align the title */
  }
  
  .reading-list-intro {
    font-size: 1.2em; /* Introductory paragraph font size */
    margin-bottom: 20px; /* Space below the intro */
    line-height: 1.6; /* Increase line height for readability */
  }
  
  .reading-list-subtitle {
    font-size: 2em; /* Subtitle font size */
    margin-top: 30px; /* Space above the subtitle */
    margin-bottom: 10px; /* Space below the subtitle */
    color: #34495e; /* Slightly lighter than the title */
  }
  
  .reading-list-paragraph {
    font-size: 1em; /* Regular paragraph font size */
    margin-bottom: 15px; /* Space below each paragraph */
    line-height: 1.5; /* Increase line height for readability */
  }
  
  .reading-list-key-areas-title {
    font-size: 1.5em; /* Key areas title font size */
    margin-top: 30px; /* Space above the key areas title */
    margin-bottom: 10px; /* Space below the title */
    color: #2980b9; /* Blue color for the key areas title */
  }
  
  .reading-list-key-areas {
    list-style-type: disc; /* Bullet points for the list */
    margin-left: 20px; /* Indent the list */
    margin-bottom: 15px; /* Space below the list */
  }
  
  .reading-list-key-area {
    margin-bottom: 10px; /* Space below each list item */
  }
  
  .reading-list-final-thought-title {
    font-size: 1.5em; /* Final thought title font size */
    margin-top: 30px; /* Space above the final thought title */
    margin-bottom: 10px; /* Space below the title */
    color: #27ae60; /* Green color for the final thought title */
  }
  
  .reading-list-final-thought {
    font-size: 1em; /* Final thought paragraph font size */
    line-height: 1.5; /* Increase line height for readability */
  }
  .reading-list-back-button:hover {
    background-color: #1a5d80; /* Darker shade on hover */
}
.back-button {
  display: block;
  width: 100px; /* Adjust width as needed */
  margin: 30px auto; /* Centers the button */
  padding: 10px 15px;
  background-color: #2980b9;
  color: white;
  text-align: center;
  text-decoration: none;
  border-radius: 5px;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.back-button:hover {
  background-color: #1c5d8e; /* Darker shade for hover effect */
}
