.founder-profile {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-top: 100px;
  }
  
  .founder-profile h1 {
    font-size: 2.5em;
    color: #2c3e50;
    margin-bottom: 10px;
  }
  
  .founder-profile h2 {
    font-size: 1.5em;
    color: #34495e;
    margin-bottom: 20px;
  }
  
  .founder-profile p {
    font-size: 1.1em;
    color: #555;
    line-height: 1.6;
    margin-bottom: 15px;
  }
  .back-button-container {
    display: flex;
    justify-content: center; /* Centers horizontally */
    align-items: center;     /* Centers vertically (optional) */
    height: 100%;            /* Optional, adjust as needed */
  }
  
  .back-button {
    /* Add any button-specific styles if needed */
    padding: 10px 20px;
    background-color: #007BFF;
    color: white;
    text-decoration: none;
    border-radius: 5px;
  }
  .close-buttonf {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 30px;
    color: #000; /* Customize the color */
    cursor: pointer;
  }
  
  .close-button:hover {
    color: #ff0000; /* Optional: Change color on hover */
  }