:root {
    --main-bg-color: #f9f9f9; /* Light background */
    --primary-text-color: #2c3e50; /* Dark blue for main text */
    --secondary-text-color: #34495e; /* Darker blue for subheadings */
    --list-item-color: #555; /* Medium grey for list items */
    --link-color: #2980b9; /* Brighter blue for links */
    --link-hover-color: #3498db; /* Lighter blue on hover */
}

.contract-staffing {
    max-width: 800px; /* Limits the width for better readability */
    margin: 0 auto; /* Centers the content */
    padding: 20px; /* Adds padding around the content */
    background-color: var(--main-bg-color); /* Light background for contrast */
    border-radius: 8px; /* Rounded corners */
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
    font-family: Arial, sans-serif; /* Sets a clean, sans-serif font */
    margin-top: 140px;
}

.heading{
    font-size: 2.5rem; /* Larger font size for the main heading */
    color: var(--primary-text-color); /* Dark text color for contrast */
    margin-bottom: 20px; /* Space below the main heading */
}

.heading {
    font-size: 1.75rem; /* Slightly smaller than h1 */
    color: var(--secondary-text-color); /* Slightly lighter dark color */
    margin-top: 20px; /* Space above subheadings */
    margin-bottom: 10px; /* Space below subheadings */
}

.p1 {
    font-size: 1rem; /* Standard font size for paragraphs */
    color: var(--primary-text-color); /* Dark text for paragraphs */
    line-height: 1.6; /* Improved line height for readability */
    margin-bottom: 15px; /* Space below paragraphs */
}

ul {
    margin-left: 20px; /* Indentation for the list */
    margin-bottom: 15px; /* Space below the list */
}

li {
    font-size: 1rem; /* Standard font size for list items */
    color: var(--list-item-color); /* Medium grey color for text */
    line-height: 1.6; /* Improved line height for readability */
    margin-bottom: 5px; /* Space between list items */
}

a {
    color: var(--link-color); /* Bright blue for links */
    text-decoration: none; /* Removes underline from links */
}

a:hover {
    text-decoration: underline; /* Underline on hover for emphasis */
    color: var(--link-hover-color); /* Change color on hover for links */
}
/* Contract.css */

.contract-staffing {
    position: relative;
    padding: 20px;
  }
  
  /* Close icon styles */
  .close-icon {
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 24px;
    color: #333; /* Adjust color as needed */
    text-decoration: none;
  }
  
  .close-icon:hover {
    color: #ff0000; /* Change color on hover */
  }
  /* Contract.css */

/* Center the back button */
.back-button1 {
    display: block;
    width: auto;
    min-width: 80px;
    padding: 6px 12px;
    background-color: #333;
    color: #fff;
    text-align: center;
    text-decoration: none;
    border-radius: 3px;
    font-size: 14px;
    transition: background-color 0.3s ease;
    margin: 0 auto; /* Center the button horizontally */
}
  
  /* Change the color on hover */
  .back-button {
    display: inline-block; /* Make it behave like a block element */
    margin: 20px auto;    /* Add some margin for spacing */
    padding: 10px 20px;   /* Add padding for a better look */
    background-color: #007bff; /* Example background color */
    color: white;         /* Text color */
    text-align: center;   /* Center text inside the button */
    border: none;         /* Remove border */
    border-radius: 5px;   /* Rounded corners */
    text-decoration: none; /* Remove underline */
    font-size: 16px;      /* Font size */
  }
  
  .contract-staffing {
    text-align: center;    /* Center text for all content if needed */
  }
  
  
  
