/* Twillo.css */

body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: #ffffff; 
  color: #000; 
}

.upreach-container {
  max-width: 800px;
  margin: 2rem auto;
  padding: 2rem;
  background-color: #ffffff; /* White background */
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  margin-top: 100px;
}

.upreach-title {
  text-align: center;
  color: #000; /* Black text color */
}

.upreach-intro {
  text-align: center;
  font-size: 1.1rem;
  color: #000; /* Black text */
  margin-bottom: 1.5rem;
}

.upreach-features-title,
.upreach-why-title {
  color: #000; /* Black text color */
  margin-top: 1.5rem;
}

.upreach-features,
.upreach-why {
  list-style-type: none;
  padding: 0;
  margin: 1rem 0;
}

.upreach-features li,
.upreach-why li {
  background: #f4f4f4; /* Light grey background for list items */
  margin: 0.5rem 0;
  padding: 1rem;
  border-radius: 5px;
  color: #000; /* Black text color for list items */
}

.upreach-get-started {
  background-color: #007bff; /* Primary brand color */
  color: white; /* White text color */
  padding: 1.5rem;
  border-radius: 8px;
  text-align: center;
  margin-top: 2rem;
}

.upreach-get-started h2 {
  margin-bottom: 0.5rem;
}

.upreach-get-started p {
  margin: 0.5rem 0;
}

/* Style for the close icon */


.back-button {
  display: inline-block;
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  text-decoration: none;
  border-radius: 4px;
}
/* Container positioning */
.upreach-container {
  position: relative;
  padding: 20px;
}

/* Close button styling */
.upreach-container button[aria-label="Close"] {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 8px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease-in-out;
}

/* Hover effect for close button */
.upreach-container button[aria-label="Close"]:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

/* Close icon color and size */
.upreach-container button[aria-label="Close"] svg {
  width: 24px;
  height: 24px;
  color: #666;
}

/* Active state when clicking */
.upreach-container button[aria-label="Close"]:active {
  background-color: rgba(0, 0, 0, 0.1);
  transform: scale(0.95);
}

/* Focus state for accessibility */
.upreach-container button[aria-label="Close"]:focus {
  outline: 2px solid #0066cc;
  outline-offset: 2px;
}

/* Focus visible only when using keyboard */
.upreach-container button[aria-label="Close"]:focus:not(:focus-visible) {
  outline: none;
}

/* Optional: Add a subtle shadow on hover */
.upreach-container button[aria-label="Close"]:hover svg {
  filter: drop-shadow(0 1px 1px rgba(0,0,0,0.1));
}