.industry-detail {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
  font-family: 'Arial', sans-serif;
  background-color: #ffffff; /* White background */
  color: #2c3e50; /* Dark blue for default text color */
  border-radius: 8px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0); /* Adjusted shadow for better depth */
  margin-top: 100px;
}

.industry-detail h1 {
  font-size: 2.5rem;
  color: #5d3f8d; /* Purple for headings */
  margin-bottom: 10px;
  text-align: center;
}

.industry-detail h2 {
  font-size: 2rem;
  color: #4e3b31; /* Dark brown for subheadings */
  margin-bottom: 20px;
  text-align: center;
}

.industry-detail p {
  font-size: 1.2rem;
  line-height: 1.6;
  color: #2c3e50; /* Dark blue for paragraphs */
  margin-bottom: 20px;
}

/* Style for bullet points */
.industry-detail ul {
  list-style-type: disc; /* Use disc bullets */
  padding-left: 20px; /* Indent list */
  margin-bottom: 20px; /* Space below the list */
}

.industry-detail li {
  font-size: 1.1rem; /* Slightly smaller font for list items */
  color: #4e3b31; /* Dark brown for list items */
  margin-bottom: 10px; /* Space between list items */
}

.not-found {
  text-align: center;
  font-size: 1.5rem;
  color: #d9534f; /* Bootstrap danger color */
}

/* Specific styles for different industry content */
.bfs-content,
.technology-content {
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px; /* Add margin between sections */
}

.bfs-content {
  background-color: white; /* Light cyan background for BFSI */
}

.technology-content {
  background-color: white; /* Light orange background for Technology */
}

/* Add similar styles for the other industry contents */
.industry-detail {
  position: relative;
  padding: 20px;
}

/* Top-right Close Icon */
.top-right-close-icon {
  position: absolute;
  top: 20px;
  right: 20px; /* Changed from left to right */
}

.top-right-close-icon a {
  color: #2c3e50; /* Dark blue for icon color */
  text-decoration: none;
}

/* Back to Home Button */
.bottom-home-button {
  display: flex;
  justify-content: center;
  margin-top: 40px;
}

.back-home-btn {
  padding: 10px 20px;
  background-color: #007bff; /* Button background color */
  color: white;
  border: none;
  cursor: pointer;
  font-size: 16px;
  border-radius: 5px;
}

.back-home-btn:hover {
  background-color: #0056b3; /* Hover effect */
}
