

.about-us-container {
  padding: 40px; /* Increased padding for full screen */
  max-width: 100%; /* Make the container take full width */
  margin: 0 auto; /* Center the container */
  background-color: #ffffff; /* White background */
  color: #000000; /* Default text color (you can remove this if not needed) */
  font-family: 'Arial', sans-serif; /* Font family for the text */
  border-radius: 8px; /* Rounded corners for the container */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Light shadow for depth */
  min-height: 100vh; /* Ensure it covers the entire viewport height */
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Space between content for Home buttons */
  margin-top: 70px;
}

.about-us-container img {
  width: 100%; /* Full-width responsive image */
  height: auto; /* Maintain aspect ratio */
  border-radius: 8px; /* Rounded corners for the image */
  margin-bottom: 20px; /* Space below the image */
}

.about-us-container h1 {
  font-size: 3rem; /* Larger font size for full-screen design */
  margin-bottom: 20px; /* Increased space below the heading */
  text-align: center; /* Center align the heading */
  color: #003366; /* Dark blue color for main heading */
}

.about-us-container h2 {
  font-size: 2.5rem; /* Larger font size for subheadings */
  margin-top: 30px; /* Increased space above the subheading */
  margin-bottom: 15px; /* Increased space below the subheading */
  color: #6A0DAD; /* Purple color for subheadings */
}

.about-us-container p {
  font-size: 1.1rem; /* Larger font size for paragraph text */
  line-height: 1.8; /* Improved line height for readability */
  margin-bottom: 20px; /* Increased space below each paragraph */
  color: #5B3A29; /* Dark brown color for paragraph text */
}

.home-button {
  position: absolute;
  top: 20px; /* Adjusted for full screen */
  left: 20px; /* Adjusted for full screen */
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 2rem; /* Larger icon for better visibility */
}

.home-button svg {
  color: #333;
}

.back-to-home-button {
  display: block;
  margin: 20px auto;
  padding: 15px 30px; /* Larger padding for a full-screen button */
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1.2rem; /* Slightly larger font size */
}

.back-to-home-button:hover {
  background-color: #0056b3;
}

/* Responsive styles for smaller screens */
@media (max-width: 768px) {
  .about-us-container {
    padding: 20px; /* Reduced padding on smaller screens */
  }

  .about-us-container h1 {
    font-size: 2.5rem; /* Smaller heading size on mobile */
  }

  .about-us-container h2 {
    font-size: 2rem; /* Smaller subheading size on mobile */
  }

  .about-us-container p {
    font-size: 1.2rem; /* Smaller paragraph text on mobile */
  }

  .home-button {
    font-size: 1.5rem; /* Adjusted icon size for mobile */
  }

  .back-to-home-button {
    padding: 10px 20px; /* Reduced padding for smaller buttons */
    font-size: 1rem;
  }
}
