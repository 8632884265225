/* Msp.css */

.managed-service-provider {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background-color: #f4f4f4; /* Light background color */
    font-family: Arial, sans-serif; /* Change to your preferred font */
    margin-top: 120px;
}

.heading {
    font-size: 2.5rem; /* Heading font size */
    color: #003366; /* Dark blue color */
    margin: 20px 0;
}

.subheading {
    font-size: 1.8rem; /* Subheading font size */
    color: #800080; /* Purple color */
    margin: 10px 0;
}

.p1 {
    font-size: 1rem; /* Paragraph font size */
    color: #4B3D3D; /* Dark brown color */
    line-height: 1.6; /* Spacing between lines */
    max-width: 800px; /* Maximum width for readability */
    margin: 10px 0;
}

img {
    max-width: 100%; /* Responsive image */
    height: auto; /* Maintain aspect ratio */
    margin: 20px 0; /* Space around the image */
    border-radius: 8px; /* Rounded corners for the image */
}

.back-button {
    margin-top: 20px; /* Space above the button */
    padding: 10px 15px; /* Padding for button */
    background-color: #007bff; /* Button background color */
    color: white; /* Button text color */
    text-decoration: none; /* No underline on link */
    border-radius: 5px; /* Rounded corners for button */
    transition: background-color 0.3s; /* Smooth transition for hover effect */
}

.back-button:hover {
    background-color: #0056b3; /* Darker color on hover */
}

.close-icone {
    position: absolute; /* Position it absolutely */
    top: 20px; /* Adjust the top position */
    right: 20px; /* Adjust the right position */
    cursor: pointer; /* Change the cursor to indicate it's clickable */
    z-index: 1000; /* Make sure it's on top of other elements */
    margin-top: 120px;
  }
  
  .close-icon svg {
    width: 30px; /* Icon width */
    height: 30px; /* Icon height */
    fill: #333; /* Icon color, adjust as needed */
    transition: fill 0.3s; /* Optional: Add a transition effect */
  }
  
  .close-icon:hover svg {
    fill: #ff0000; /* Change color on hover, adjust as needed */
  }
  