body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    background-color: #ffffff;
    color: #00008B; /* Change body text color to dark blue */
}

.shopfloor-container {
    max-width: 800px;
    margin: 2rem auto;
    padding: 2rem;
    background-color: #ffffff; /* White background for the container */
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    margin-top: 100px;
}

.shopfloor-title {
    text-align: center;
    color: #800080; /* Set title text color to purple */
    font-size: 1.8rem; /* Larger font size for the title */
}

.shopfloor-intro {
    text-align: center;
    font-size: 1.1rem;
    color: #8B4513; /* Set intro text color to dark brown */
    margin-bottom: 1.5rem;
}

.shopfloor-why-title {
    color: #800080; /* Set subtitle text color to purple */
    margin-top: 1.5rem;
    text-align: center; /* Center align the subtitle */
}

.shopfloor-features {
    list-style-type: none;
    padding: 0;
    margin: 1rem 0;
}

.shopfloor-features li {
    background: #e9ecef; /* Light grey background for list items */
    margin: 0.5rem 0;
    padding: 1rem;
    border-radius: 5px;
    color: #00008B; /* Set feature list item text color to dark blue */
    font-size: 1rem; /* Default font size for features */
}

.shopfloor-get-started {
    text-align: center; /* Center align the get started section */
    margin-top: 2rem;
    padding: 1.5rem;
    border-top: 1px solid #e1e1e1; /* Top border for separation */
}

.shopfloor-get-started h2 {
    margin-bottom: 0.5rem;
    color: #800080; /* Set text color to purple */
}

.shopfloor-get-started p {
    margin: 0.5rem 0;
    color: #8B4513; /* Set paragraph text color to dark brown */
}

.shopfloor-demo-button {
    background-color: #007bff; /* Primary brand color */
    color: white; /* White text */
    border: none;
    border-radius: 5px;
    padding: 0.75rem 1.5rem;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s ease;
}

.shopfloor-demo-button:hover {
    background-color: #0056b3; /* Darker shade on hover */
}

/* Close icon at top-right */
.close-icon {
    position: absolute;
    top: 20px;
    right: 20px;
    color: #00008B; /* Set icon color to dark blue */
    text-decoration: none;
}

.close-icon:hover {
    color: #ff0000; /* Optional: Change color on hover */
}

/* Other styles for your component */
.shopfloor-container {
    padding: 20px;
    /* Additional styling */
}

.back-button {
    display: inline-block;
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    text-decoration: none;
    border-radius: 5px;
    cursor: pointer;
}

.back-button:hover {
    background-color: #0056b3;
}

.close-icon {
    position: absolute; /* Position it absolutely */
    top: 20px; /* Adjust as needed */
    right: 20px; /* Adjust as needed */
    cursor: pointer; /* Change cursor to pointer */
}

.close-icon-img {
    font-size: 24px; /* Adjust the size as needed */
    color: #00008B; /* Change color to dark blue */
}

.shopfloor-container {
    position: relative; /* Set position to relative for absolute positioning of the close icon */
}
.shopfloor-container {
    position: relative;
    padding: 20px;
  }
  
  .closes-button {
    position: absolute;
    top: 20px;
    right: 20px;
    background: none;
    border: none;
    font-size: 40px;
    cursor: pointer;
    color: #333; /* Adjust color as needed */
  }
  
  .closes-button:hover {
    color: #ff0000; /* Change to a hover color if desired */
  }
  