.about {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    gap: 1.5rem;
    padding: 2rem; /* Reduce padding for less background space */
    max-width: 1200px; /* Set a max-width to avoid stretching */
    margin: 0 auto; /* Center the section */
}

.about-img img {
    max-width: 100%; /* Ensure the image takes full width of the container */
    height: auto;
    border-radius: 8px;
}

.about-text h2 {
    font-size: 2rem; /* Adjust font size */
    line-height: 1.2;
    margin-bottom: 1rem; /* Add space between the heading and text */
}

.about-text p {
    color: var(--second-color);
    font-size: 1rem; /* Adjust font size for better readability */
    line-height: 1.6;
    margin-bottom: 2rem; /* Adjust margin for less space between paragraphs */
}

.our p {
    margin-bottom: 1.5rem; /* Reduce space between the mission and vision statements */
}

/* Add responsiveness for smaller screens */
@media (max-width: 768px) {
    .about {
        grid-template-columns: 1fr; /* Stack content on smaller screens */
        text-align: center;
        padding: 1rem; /* Reduce padding */
    }
    
    .about-img img {
        max-width: 100%;
        margin-bottom: 1rem; /* Add space below the image */
    }
    
    .about-text h2 {
        font-size: 1.8rem; /* Adjust heading size for smaller screens */
    }
    
    .about-text p {
        font-size: 0.9rem; /* Slightly smaller text for readability */
    }
}
