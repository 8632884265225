.four-container {
    padding: 20px;
    font-family: Arial, sans-serif;
    color: #333;
    line-height: 1.6;
    background-color: #f9f9f9;
    margin-top: 100px;
  }
  
  .four-image {
    max-width: 100%;
    height: auto;
    margin-bottom: 20px;
    border-radius: 8px;
  }
  
  .four-title {
    font-size: 28px;
    color: #2c3e50;
    margin-bottom: 20px;
  }
  
  .four-intro {
    font-size: 18px;
    margin-bottom: 20px;
  }
  
  .four-subtitle {
    font-size: 22px;
    color: #34495e;
    margin: 30px 0 10px;
  }
  
  .four-heading {
    font-size: 20px;
    color: #2980b9;
    margin: 20px 0 10px;
  }
  
  .four-text {
    font-size: 16px;
    margin-bottom: 20px;
    color: #555;
  }
  
  .back-button {
    display: inline-block;
    padding: 10px 20px;
    font-size: 16px;
    color: #fff;
    background-color: #3498db;
    border-radius: 5px;
    text-decoration: none;
    transition: background-color 0.3s;
  }
  
  .back-button:hover {
    background-color: #2980b9;
  }
  .four-container {
    padding: 20px;
    font-family: Arial, sans-serif;
    color: #333;
    line-height: 1.6;
    background-color: #f9f9f9;
    display: flex;
    flex-direction: column;
    align-items: center; /* Center content horizontally */
  }
  
  .back-button {
    display: inline-block;
    padding: 10px 20px;
    font-size: 16px;
    color: #fff;
    background-color: #3498db;
    border-radius: 5px;
    text-decoration: none;
    transition: background-color 0.3s;
    margin-top: 20px; /* Add margin if needed */
  }
  
  .back-button:hover {
    background-color: #2980b9;
  }
  .four-container {
  position: relative;
  padding: 20px;
}

.close-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  cursor: pointer;
  color: #333; /* Change color as needed */
}

.close-icon:hover {
  color: #ff0000; /* Color on hover */
}

.four-image {
  width: 100%;
  height: auto;
}

.four-title {
  font-size: 24px;
  margin-top: 20px;
}

.four-intro, .four-text {
  font-size: 16px;
  line-height: 1.6;
  margin-bottom: 20px;
}

.four-subtitle, .four-heading {
  font-size: 20px;
  margin-top: 20px;
}

.back-button {
  display: inline-block;
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  text-decoration: none;
  border-radius: 4px;
}

.back-button:hover {
  background-color: #0056b3;
}
