
.contact {
  background: var(--second-bg-color);
  padding: 4rem 15%;
}

.contact-text h2 {
  font-size: var(--h2-font);
  line-height: 1;
  margin-bottom: 1rem;
}

.contact-text p {
  color: var(--second-color);
  font-size: var(--p-font);
  line-height: 1.8;
  margin-bottom: 2rem;
}

.branches-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  margin-bottom: 3rem;
}

.branch {
  background: var(--bg-color);
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.branch h3 {
  color: yellow;
  font-size: 1.2rem;
  margin-bottom: 1rem;
}

.branch p {
  color: whitesmoke;
  font-size: 0.9rem;
  line-height: 1.6;
  margin-bottom: 0.5rem;
}

.contact-link {
  color: #1da1f2;
  font-weight: bold;
  text-decoration: none;
  transition: color 0.3s ease;
}



.phone-link {
  color: yellow;
}

.email-link {
  color: yellow;
}
.hi{
  color:#f0f0f0;
}
.contact-form form {
  display: grid;
  gap: 1rem;
}

.contact-form form input,
.contact-form form textarea {
  width: 100%;
  padding: 1rem;
  background: var(--bg-color);
  color: var(--text-color);
  border: 1px solid var(--second-color);
  outline: none;
  font-size: 1rem;
  border-radius: 8px;
}

.contact-form textarea {
  resize: vertical;
  min-height: 150px;
}

.contact-form .submit {
  background: var(--main-color);
  color: var(--text-color);
  font-size: 1rem;
  padding: 1rem;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.contact-form .submit:hover {
  background: var(--text-color);
  color: var(--main-color);
}
.clients-heading{
  color: #f0f0f0;
}

@media (max-width: 768px) {
  .contact {
    padding: 2rem 5%;
  }

  .branches-grid {
    grid-template-columns: 1fr;
  }
}


/* Existing styles... */

.clients-section {
  margin: 2rem 0;
}

.clients-section h3 {
  text-align: center;
  margin-bottom: 1rem;
}

.clients-slider {
  display: flex;
  overflow-x: auto;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;  /* Firefox */
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
}

.clients-slider::-webkit-scrollbar { 
  display: none;  /* WebKit */
}

.client-logo {
  flex: 0 0 auto;
  width: 150px;
  height: 80px;
  margin-right: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f0f0f0;
  border-radius: 8px;
}

.client-logo img {
  max-width: 80%;
  max-height: 80%;
  object-fit: contain;
}

.clients-slider.active {
  cursor: grabbing;
  cursor: -webkit-grabbing;
}

.branch iframe {
  margin-top: 10px;
  border-radius: 5px;
}

.clients-heading {
  font-size: 1.5rem; /* Increase font size */
  font-weight: bold; /* Optional: Make it bold */
  text-align: center; /* Optional: Center the text */
  margin-bottom: 10px; /* Optional: Add space below the heading */
}
