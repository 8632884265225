
*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
    text-decoration: none;
    list-style: none;
    scroll-behavior: smooth;
}
:root {
  /* Background Colors */
  --bg-color: #1f1f1f; /* Darker, more neutral */
  --second-bg-color: #2e2e2e; /* Slight contrast with main background */
  
  /* Text Colors */
  --text-color: #f2f2f2; /* Slightly off-white for better readability */
  --second-color: #a6a6a6; /* Softer gray for secondary text */
  
  /* Accent Color */
  --main-color: #ff5733; /* Slightly warmer, still bold */
  
  /* Font Sizes */
  --big-font: 4.8rem; /* Adjusted for balance */
  --h2-font: 2.8rem; /* Adjusted for consistency */
  --p-font: 1.2rem; /* Slightly larger for readability */
}

body{
    background: var(--bg-color);
    color: var(--text-color);
}
header{
    position: fixed;
    width: 100%;
    top: 0;
    right: 0;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: transparent;
    padding: 22px 15%;
    border-bottom: 1px solid transparent;
    transition: all .45s ease;
}
.logo{
    color: var(--text-color);
    font-size: 35px;
    font-weight: 700;
    letter-spacing: 1px;
}
span{
    color: var(--main-color);
}
.navlist{
    display: flex;
}
.navlist a{
    color: var(--second-color);
    font-size: 17px;
    font-weight: 500;
    margin: 0 25px;
    transition: all .45s ease;
}
.navlist a:hover{
    color: var(--main-color);
}
.navlist a.active{
    color: var(--main-color);
}
#menu-icon{
    font-size: 35px;
    color: var(--text-color);
    z-index: 10001;
    cursor: pointer;
    margin-left: 25px;
    display: none;
}

section{
    padding: 160px 15% 120px;
}
.home{
    position: relative;
    height: 100vh;
    width: 100%;
   
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.slide{
    margin-bottom: 20px;
}
.one{
    display: inline-block;
    margin-right: 15px;
    height: 32px;
    padding: 0 15px;
    line-height: 32px;
    font-size: 20px;
    font-weight: 500;
    border-radius: 5px;
    background: var(--main-color);
    color: var(--text-color);
}
.two{
    display: inline-block;
    color: var(--second-color);
    font-size: 20px;
    font-weight: 500;
}
.home-text h1{
    font-size: var(--big-font);
    font-weight: 700;
    color: var(--text-color);
    line-height: 1.1;
    margin: 0 0 8px;
}
.home-text h3{
    color: var(--text-color);
    margin: 0 0 35px;
    font-size: 3rem;
    font-weight: 700;
    line-height: 1.1;
}
.home-text p{
    color: var(--second-color);
    font-size: var(--p-font);
    line-height: 1.8;
    margin-bottom: 40px;
}
.button{
    display: inline-flex;
    align-items: center;
    justify-content: center;
}
.btn{
    display: inline-block;
    padding: 12px 28px;
    background: var(--main-color);
    border-radius: 5px;
    color: var(--text-color);
    font-size: 1rem;
    letter-spacing: 1px;
    font-weight: 600;
    transition: all .45s ease;
}
.btn:hover{
    transform: scale(0.9);
}
.btn2{
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    font-weight: 600;
    color: var(--text-color);
    margin-left: 35px;
}
.btn2 span i{
    height: 55px;
    width: 55px;
    background: var(--main-color);
    color: var(--text-color);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 35px;
    border-radius: 50%;
    margin-right: 15px;
    transition: all .45s ease;
}
.btn2 span i:hover{
    transform: scale(1.1) translateY(5px);
}

header.sticky{
    background: var(--bg-color);
    border-bottom: 1px solid #ffffff1a;
    padding: 12px 15%;
}


.services{
    background: var(--second-bg-color);
}
.main-text{
    text-align: center;
}
.main-text p{
    color: var(--second-color);
    font-size: 15px;
    margin-bottom: 15px;
}
.main-text h2{
    font-size: var(--h2-font);
    line-height: 1;
}

@media (max-width: 1480px){
    header{
        padding: 12px 2.5%;
        transition: .1s;
    }
    header.sticky{
        padding: 10px 2.5%;
        transition: .1s;;
    }
    section{
        padding: 110px 3% 60px;
    }
    .end{
        padding: 15px 3%;
    }
}

@media (max-width: 1100px){
    :root{
        --big-font: 4rem;
    --h2-font: 2.5rem;
    --p-font: 1rem;
    transition: .1s;
    }
    .home-text h3{
        font-size: 2.5rem;
    }
    .home{
        height: 87vh;
    }
}

@media (max-width: 920px){
    .about{
        grid-template-columns: 1fr;
    }
    .about-img{
        text-align: center;
        order: 2;
    }
    .about-img img{
        width: 100%;
        height: auto;
        max-width: 100%;
    }
    .contact{
        grid-template-columns: 1fr;
    }
}

@media (max-width: 840px){
    #menu-icon{
        display: block;
    }
    .navlist{
        position: absolute;
        top: -1000px;
        right: 0;
        left: 0;
        display: flex;
        flex-direction: column;
        background: var(--bg-color);
        text-align: left;
        transition: all .45s ease;
    }
    .navlist a{
        display: block;
        margin: 17px;
        font-size: 20px;
        transition: all .45s ease;
        color: black;
    }
    .navlist a:hover{
        color: var(--main-color);
    }
    .navlist a.active{
        color: var(--text-color);
    }
    .navlist.open{
        top: 100%;
    }
}

.home {
    position: relative;
    padding: 50px 0;
  }
  
  .home-slide {
    text-align: center;
    padding: 20px;
  }
  
  .home-text {
    max-width: 800px;
    margin: 0 auto;
  }
  
  .home-text h1 {
    font-size: 1.5rem;
    margin-bottom: 20px;
    padding-right: 20px;
  }
  
  .home-text h3 {
    font-size: 2.5rem;
    margin-bottom: 20px;
  }
  
  .home-text p {
    font-size: 1.3rem;
    margin-bottom: 30px;
    
  }
  
  .button .btn {
    display: inline-block;
    padding: 10px 20px;
    background-color: #333;
    color: white;
    border-radius: 5px;
    text-decoration: none;
  }
  
  
  .button .btn:hover {
    background-color: #555;
  }
  

  .banner-carousel {
  position: relative;
  height: 200px; /* Adjust as needed */
  overflow: hidden;
}

.banner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.banner.active {
  opacity: 1;
}

.banner h2 {
  font-size: 24px;
  margin-bottom: 10px;
}

.banner p {
  font-size: 16px;
}
  
.home {
    position: relative;
    overflow: hidden;
  }
  
  .banner-slider {
    display: flex;
    transition: transform 0.5s ease-in-out;
  }
  
  .banner-slide {
    min-width: 100%;
    position: relative;
    color: #fff; /* Default white text */
    display: flex;
    align-items: center;
    justify-content: center;
    height: 600px;
  }
  
  .slide-image {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-size: cover;
    background-position: center;
    z-index: -1;
    filter: brightness(70%); /* Darken the image slightly */
  }
  
  .slide-content {
    z-index: 2;
    max-width: 800px;
    text-align: center;
    padding: 20px;
  }
  
  h2 {
    font-size: 2.5rem;
    margin-bottom: 15px;
    /* Shadow for readability */
  }
  
  p1 {
    font-size: 1.25rem;
    margin-bottom: 20px;
    text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.7); /* Shadow for readability */
  }
  
  .cta-button {
    padding: 10px 20px;
    font-size: 1.2rem;
    background-color: #ff4a57;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .cta-button:hover {
    background-color: #e23b47;
  }
  
  .slider-nav {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: rgba(0, 0, 0, 0.3);
    color: white;
    border: none;
    font-size: 2rem;
    padding: 10px;
    cursor: pointer;
    z-index: 10;
  }
  
  .slider-nav.prev {
    left: 10px;
  }
  
  .slider-nav.next {
    right: 10px;
  }
  
  .slider-dots {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    gap: 10px;
  }
  
  .dot {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.7);
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .dot.active {
    background-color: #ff4a57;
  }
  


/* General styling for the home section */
.home {
  position: relative;
  overflow: hidden;
}

.banner-slider {
  display: flex;
  transition: transform 0.5s ease-in-out;
  width: 100%;
}

.banner-slide {
  min-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  text-align: center;
}

.slide-image {
  width: 100%;
  height: 100vh; /* Full screen height */
  background-size: cover;
  background-position: center;
  transition: transform 0.5s ease-in-out;
}

.slide-content {
  position: absolute;
  bottom: 20%;
  left: 50%;
  transform: translateX(-50%);
  color: #fff;
  text-shadow: 0 2px 5px rgba(0, 0, 0, 0.8);
  padding: 20px;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  max-width: 90%;
}

.slide-content h2 {
  font-size: 2.5rem;
  margin-bottom: 10px;
}

.slide-content p {
  font-size: 1.2rem;
}

.slider-nav {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  font-size: 2rem;
  padding: 10px;
  cursor: pointer;
  z-index: 10;
}

.prev {
  left: 10px;
}

.next {
  right: 10px;
}

.slider-dots {
  text-align: center;
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
}

.dot {
  height: 15px;
  width: 15px;
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 50%;
  display: inline-block;
  margin: 0 5px;
  cursor: pointer;
}

.dot.active {
  background-color: white;
}

/* Mobile Styles */
@media (max-width: 768px) {
  .slide-image {
    height: 50vh; /* Reduce the image height on mobile */
  }

  .slide-content {
    bottom: 10%;
    padding: 15px;
  }

  .slide-content h2 {
    font-size: 1.8rem; /* Reduce font size for mobile */
  }

  .slide-content p {
    font-size: 1rem;
  }

  .slider-nav {
    font-size: 1.5rem;
    padding: 5px;
  }
}

/* Extra Small Mobile */
@media (max-width: 480px) {
  .slide-content h2 {
    font-size: 1.5rem; /* Further reduce font size for smaller mobile screens */
  }

  .slide-content p {
    font-size: 0.9rem;
  }

  .slide-image {
    height: 40vh; /* Further reduce the image height on very small devices */
  }
}
