/* WorkforceDiversity.css */

.workforce-diversity {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    color: #333;
    font-family: Arial, sans-serif;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-top: 100px;
  }
  
  .workforce-diversity-title {
    font-size: 32px;
    color: #2c3e50;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .workforce-diversity-intro {
    font-size: 18px;
    line-height: 1.6;
    color: #555;
    margin-bottom: 20px;
  }
  
  .workforce-diversity-subtitle {
    font-size: 24px;
    color: #2980b9;
    margin-bottom: 15px;
    margin-top: 25px;
  }
  
  .workforce-diversity-text {
    font-size: 16px;
    line-height: 1.7;
    margin-bottom: 20px;
    color: #444;
  }
  
  .infoway-message {
    font-style: italic;
    color: #2980b9;
    text-align: center;
    margin-top: 30px;
    font-size: 18px;
  }
  .back-button {
    display: block;
    width: 100px; /* Adjust width as needed */
    margin: 30px auto; /* Centers the button */
    padding: 10px 15px;
    background-color: #2980b9;
    color: white;
    text-align: center;
    text-decoration: none;
    border-radius: 5px;
    font-size: 16px;
    transition: background-color 0.3s ease;
}

.back-button:hover {
    background-color: #1c5d8e; /* Darker shade for hover effect */
}
