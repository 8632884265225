.industry-card {
  border: 1px solid #ccc;
  border-radius: 8px;
  margin: 10px;
  padding: 15px;
  transition: transform 0.3s;
}

.industry-card:hover {
  transform: scale(1.05);
}

.industry-card h3 {
  font-size: 1.8rem;
  color: #333;
}

.industry-card p {
  font-size: 1rem;
  color: #777;
}

/* Responsive styles */
@media (max-width: 768px) {
  .industry-detail {
    padding: 15px;
  }

  .industry-detail h1 {
    font-size: 2rem;
  }

  .industry-detail h2 {
    font-size: 1.5rem;
  }

  .industry-detail p {
    font-size: 1rem;
  }
}
