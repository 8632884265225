body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: #ffffff; /* White background for the body */
  color: #000; /* Default text color (black) */
  text-shadow: none; 
  
}

.breezhr-container {
  max-width: 800px;
  margin: 2rem auto;
  padding: 2rem;
  background-color: #ffffff; /* White background for the container */
  border-radius: 8px;
  box-shadow: none; /* Remove any shadow for a clean view */
  position: relative; /* Allows positioning of child elements */
  display: flex; /* Use Flexbox for centering */
  flex-direction: column; /* Align items vertically */
  align-items: center; /* Center items horizontally */
  text-align: center; /* Center text for all items */
  margin-top: 50px;
}

.breezhr-title {
  font-size: 2.5rem; /* Larger font size for the title */
  margin: 40px 0 1rem; /* Top margin for spacing */
}

.breezhr-intro,
.breezhr-subtitle {
  font-size: 1.1rem; /* Default font size for intro and subtitles */
  margin: 20px 0; /* Vertical spacing */
  color: #000; /* Black text for better readability */
}

.breezhr-subtitle {
  font-size: 1.8rem; /* Slightly larger font size for subtitles */
}

.breezhr-functions {
  list-style-type: disc; /* Use bullet points */
  padding: 0;
  margin: 20px 0; /* Vertical spacing */
  text-align: left; /* Align text to the left for the list */
  width: 100%; /* Ensure list takes full width */
}

.breezhr-functions li {
  background: #ffffff; /* White background for list items */
  margin: 0.5rem 0;
  padding: 1rem;
  border-radius: 5px;
  font-size: 1rem; /* Default font size for functions */
}

.back-button {
  display: inline-block; /* Make it an inline block for centering */
  margin: 40px auto; /* Add margin to separate it from other elements */
  padding: 10px 20px; /* Add some padding for aesthetics */
  background-color: #007bff; /* Primary brand color */
  color: white; /* Text color */
  text-align: center; /* Center text */
  border: none; /* No border */
  border-radius: 5px; /* Rounded corners */
  text-decoration: none; /* Remove underline from link */
  transition: background-color 0.3s; /* Smooth transition for hover effect */
}

.back-button:hover {
  background-color: #0056b3; /* Darker shade on hover */
}

.image-container {
  display: flex; /* Use flexbox */
  justify-content: center; /* Center the image horizontally */
  margin: 20px 0; /* Space above and below the image container */
}

.breezhr-main-image {
  width: 70%; /* Make the image responsive */
  max-width: 600px; /* Set a max width for larger screens */
  border-radius: 8px; /* Rounded corners */
}


/* New color classes */
.text-dark-blue {
  color: #003366; /* Dark blue color */
}

.text-purple {
  color: #800080; /* Purple color */
}

.text-dark-brown {
  color: #4B3D3D; /* Dark brown color */
}
.breezhr-container {
  position: relative; /* Ensure positioning context for absolute children */
  /* Add some padding */
}
.breezhr-container {
  position: relative;
}

.close-button {
  position: absolute;
  top: 20px;
  right: 20px;
  background: none;
  border: none;
  font-size: 24px;
  color: #333; /* Adjust color to match your theme */
  cursor: pointer;
  padding: 5px;
  transition: color 0.3s;
  margin-top: 50px;
}

.close-button:hover {
  color: #555; /* Adjust hover color */
}

