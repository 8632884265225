.onsite-recruitment {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    color: #333;
    font-family: Arial, sans-serif;
    line-height: 1.6;
    margin-top: 120px;
  }
  
  .onsite-recruitment h2 {
    color: #003366;
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .onsite-recruitment h3 {
    color: #003366;
    font-size: 20px;
    margin-top: 20px;
  }
  
  .onsite-recruitment ul {
    margin-left: 20px;
    list-style-type: disc;
  }
  
  .onsite-recruitment p {
    margin-bottom: 15px;
  }
  
  .onsite-recruitment a {
    color: #0073e6;
    text-decoration: none;
  }
  .onsite-recruitment a:hover {
    text-decoration: underline;
  }
  .onsite-recruitment {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center horizontally */
    justify-content: center; /* Optional: center vertically */
    text-align: center;
  }
  


  .back-button {
    display: inline-block;
    padding: 10px 20px;
    margin-top: 20px;
     /* Bright blue for better visibility */
    color: white; /* Contrasting white text */
    text-decoration: none;
    border-radius: 5px;
    font-size: 16px;
    transition: background-color 0.3s ease, color 0.3s ease; /* Smooth color transition */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Optional: Add a subtle shadow for emphasis */
}

.back-button:hover {
    background-color: #0056b3; /* Darker blue on hover */
    color: #ffffff; /* Maintain contrasting white text on hover */
}
