:root {
  --primary-color: #0056b3;
  --secondary-color: #004085;
  --text-color: #333;
  --background-color: #f8f9fa;
  --hover-color: rgba(0, 0, 0, 0.05);
  --dark-black: #000000;
  --nav-text-color: var(--dark-black);
  --nav-hover-color: #0056b3;
  --nav-bg-color: transparent;
  --nav-hover-bg-color: #e9ecef;
  --dropdown-bg-color: #ffffff;
  --dropdown-text-color: var(--dark-black);
  --dropdown-hover-bg: #f0f0f0;
  --dropdown-hover-text: #0056b3;
}


/* Header container */
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 5%;
  background-color: var(--background-color);
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

/* Logo */
.logo img {
  width: 100px;
  height: auto;
}

/* Nav list */
.navlist {
  display: flex;
  justify-content: space-around;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;
  flex-grow: 1;
}

/* Styles for all top-level nav items */
.navlist > li > .nav-item {
  color: var(--nav-text-color);
  text-decoration: none;
  padding: 0.5rem 1rem;
  transition: color 0.3s ease;
  font-weight: bold;
  display: flex;
  align-items: center;
  font-size: 16px;
}

/* Hover effect for top-level nav items */
.navlist > li > .nav-item:hover {
  color: var(--nav-hover-color);
}

/* Dropdown container */
.dropdown {
  position: relative;
}

/* Dropdown toggle styling */
.dropdown-toggle {
  cursor: pointer;
  font-size: 14px;
  display: flex;
  align-items: center;
}

/* Chevron arrow styling */
.dropdown-arrow {
  margin-left: 0.5rem;
  transition: transform 0.3s ease;
}

/* Chevron arrow rotation when open */
.dropdown-arrow.open {
  transform: rotate(180deg);
}

/* Dropdown menu styling */
.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: var(--dropdown-bg-color);
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  display: none;
  z-index: 1000;
  min-width: 150px;
  padding: 0.25rem 0;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15); 
}

/* Make dropdown visible when active */
.dropdown-menu.active {
  display: block;
}

/* Dropdown menu items */
.dropdown-menu .nav-item {
  display: block;
  padding: 0.5rem 0.75rem;
  color: var(--dropdown-text-color);
  background-color: transparent;
  text-decoration: none;
  transition: background-color 0.3s ease, color 0.3s ease;
  font-size: 12px;
  font-weight: 500;
}

/* Hover effect on dropdown items */
.dropdown-menu .nav-item:hover {
  background-color: var(--dropdown-hover-bg);
  color: var(--dropdown-hover-text);
  border-left: 3px solid var(--primary-color);
}

/* Mobile Menu */
.menu-icon {
  display: none;
  cursor: pointer;
  font-size: 1.5rem;
}

/* Mobile view adjustments */
@media (max-width: 768px) {
  .navlist {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 70px;
    left: 0;
    right: 0;
    background-color: var(--background-color);
    border-top: 1px solid var(--hover-color);
  }

  .navlist.active {
    display: flex;
  }

  .nav-item {
    padding: 1rem;
    font-size: 14px;
  }

  .dropdown-menu {
    position: static;
  }

  .dropdown-menu .nav-item {
    padding-left: 2rem;
  }

  .menu-icon {
    display: block;
  }
}
/* Adjusting nav bar size */

/* Header container */
.header {
  padding: 0.5rem 3%; /* Reduced padding */
}

/* Logo */
.logo img {
  width: 80px; /* Reduced logo size */
}

/* Nav list */
.navlist {
  flex-grow: 0; /* Removed flex-grow */
}

/* Top-level nav items */
.navlist > li > .nav-item {
  font-size: 14px; /* Smaller font size */
  padding: 0.25rem 0.75rem; /* Reduced padding */
}

/* Dropdown toggle styling */
.dropdown-toggle {
  font-size: 12px; /* Smaller font size */
}

/* Dropdown menu items */
.dropdown-menu .nav-item {
  font-size: 11px; /* Smaller font size */
  padding: 0.25rem 0.5rem; /* Reduced padding */
}

/* Mobile Menu */
.menu-icon {
  font-size: 1.2rem; /* Smaller icon size */
}

/* Mobile view adjustments */
@media (max-width: 768px) {
  .navlist {
    top: 60px; /* Adjust position for smaller nav bar */
  }

  .nav-item {
    padding: 0.75rem; /* Reduced padding in mobile view */
    font-size: 13px; /* Slightly smaller font size for mobile */
  }
}
