.services-content {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 2rem;
    margin-top: 2rem;
  }
  
  .box {
    background: var(--bg-color);
    padding: 1.5rem;
    border-radius: 8px;
    transition: all .45s ease;
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  
  .card-image {
    position: relative;
    overflow: hidden;
    border-radius: 8px;
    margin-bottom: 1rem; /* This creates the gap between image and title */
  }
  
  .card-image img {
    width: 100%;
    height: 200px; /* Fixed height for consistency */
    object-fit: cover; /* This ensures the image covers the area without distortion */
    display: block;
  }
  
  .layer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(rgba(0,0,0,0.1), #2768d9);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    padding: 0 2rem;
    opacity: 0;
    transition: all .45s ease;
  }
  
  .layer p {
    color: var(--second-color);
    font-size: 0.9rem;
    line-height: 1.8;
  }
  
  .layer i {
    color: var(--main-color);
    margin-top: 0.5rem;
    font-size: 1.25rem;
    background: var(--text-color);
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
  }
  
  .box:hover .layer {
    opacity: 1;
  }
  
  .card-content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }
  
  .box h3 {
    font-size: 1.25rem;
    font-weight: 600;
    color: var(--text-color);
    margin-bottom: 0.75rem;
  }
  
  .box p {
    color: var(--second-color);
    font-size: 1rem;
    line-height: 1.8;
    margin-bottom: 1rem;
  }
  
  .read {
    display: inline-block;
    padding: 0.5rem 1rem;
    background: #333333;
    color: var(--second-color);
    font-size: 0.875rem;
    font-weight: 500;
    border-radius: 4px;
    transition: all .45s ease;
    margin-top: auto;
  }
  
  .read:hover {
    letter-spacing: 1px;
    background: #ffffff99;
    color: var(--text-color);
  }
  
  .box:hover {
    transform: translateY(-8px);
    cursor: pointer;
  }

  
.services{
  background: var(--second-bg-color);
}
.main-text{
  text-align: center;
}
.main-text p{
  color: var(--second-color);
  font-size: 15px;
  margin-bottom: 15px;
}
.main-text h2{
  font-size: var(--h2-font);
  line-height: 1;
}
.services-content{
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, auto));
  align-items: center;
  gap: 2.5rem;
  margin-top: 5rem;
}
.box{
  background: var(--bg-color);
  padding: 35px 45px;
  border-radius: 8px;
  transition: all .45s ease;
}
.s-icons i{
  font-size: 32px;
  margin-bottom: 20px;
}
.box h3{
  font-size: 24px;
  font-weight: 600;
  color: var(--text-color);
  margin-bottom: 15px;
}
.box p{
  color: var(--second-color);
  font-size: 1rem;
  line-height: 1.8;
  margin-bottom: 25px;
}
.read{
  display: inline-block;
  padding: 8px 18px;
  background: #333333;
  color: var(--second-color);
  font-size: 14px;
  font-weight: 500;
  border-radius: 8px;
  transition: all .45s ease;
}
.read:hover{
  letter-spacing: 1px;
  background: #ffffff99;
  color: var(--text-color);
}
.box:hover{
  transform: translateY(-8px);
  cursor: pointer;
}

.portfolio-content{
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, auto));
  align-items: center;
  gap: 2.5rem;
  margin-top: 5rem;
}
.row{
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  cursor: pointer;
}
.row img{
  width: 100%;
  border-radius: 8px;
  display: block;
  transition: transform 0.5s;
}
.layer{
  width: 100%;
  height: 0;
  background: linear-gradient(rgba(0,0,0,0.1), #ff004f);
  position: absolute;
  border-radius: 8px;
  left: 0;
  bottom: 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0 40px;
  transition: height 0.5s;
}
.layer h5{
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 15px;
}
.layer p{
  color: var(--second-color);
  font-size: 1rem;
  line-height: 1.8;
}
.layer i{
  color: var(--main-color);
  margin-top: 20px;
  font-size: 20px;
  background: var(--text-color);
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}
.row:hover img{
  transform: scale(1.1);
}
.row:hover .layer{
  height: 100%;
}
.si{
  color: white;
}