.services{
    background: var(--second-bg-color);
}
.main-text{
    text-align: center;
}
.main-text p{
    color: var(--second-color);
    font-size: 15px;
    margin-bottom: 15px;
}
.main-text h2{
    font-size: var(--h2-font);
    line-height: 1;
}
.services-content{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, auto));
    align-items: center;
    gap: 2.5rem;
    margin-top: 5rem;
}
.box{
    background: var(--bg-color);
    padding: 35px 45px;
    border-radius: 8px;
    transition: all .45s ease;
}
.s-icons i{
    font-size: 32px;
    margin-bottom: 20px;
}
.box h3{
    font-size: 24px;
    font-weight: 600;
    color: var(--text-color);
    margin-bottom: 15px;
}
.box p{
    color: var(--second-color);
    font-size: 1rem;
    line-height: 1.8;
    margin-bottom: 25px;
}
.read{
    display: inline-block;
    padding: 8px 18px;
    background: #333333;
    color: var(--second-color);
    font-size: 14px;
    font-weight: 500;
    border-radius: 8px;
    transition: all .45s ease;
}
.read:hover{
    letter-spacing: 1px;
    background: #ffffff99;
    color: var(--text-color);
}
.box:hover{
    transform: translateY(-8px);
    cursor: pointer;
}

.ei{
    color :azure;
}