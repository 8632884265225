body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    background-color: #ffffff; /* White background for the body */
    color: #000; /* Default text color (black) */
}

.breezlearn-container {
    max-width: 800px;
    margin: 2rem auto;
    padding: 2rem;
    background-color: #ffffff; /* White background for the container */
    border-radius: 8px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    margin-top: 100px;
}

.breezlearn-title {
    text-align: center;
    color: black; /* Primary brand color for the title */
    font-size: 2.5rem; /* Larger font size for the title */
    margin-bottom: 1rem;
}

.breezlearn-intro {
    text-align: center;
    font-size: 1.1rem;
    color: #000; /* Black text for better readability */
    margin-bottom: 2rem;
}

.breezlearn-subtitle {
    color: black; /* Primary brand color for subtitles */
    font-size: 1.8rem; /* Slightly larger font size for subtitles */
    margin-top: 1.5rem;
}

.breezlearn-solutions {
    list-style-type: none; /* Remove bullet points */
    padding: 0;
    margin: 1rem 0;
}

.breezlearn-solutions li {
    background: #ffffff; /* White background for list items */
    margin: 0.5rem 0;
    padding: 1rem;
    border-radius: 5px;
    font-size: 1rem; /* Default font size for solutions */
    color: #000; /* Black text color for list items */
}
.breezlearn-container {
    position: relative;
    padding: 20px;
    /* Other styles */
  }
  
  .close-icons {
    position: absolute;
    top: 20px;
    right: 20px;
    background: none;
    border: none;
    font-size: 40px;
    cursor: pointer;
    color: #333; /* Adjust to your preferred color */
  }
  
  .close-icon:hover {
    color: #ff0000; /* Change to your preferred hover color */
  }
  