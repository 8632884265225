.service-detail-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start; /* Changed from center to align content to the top */
  align-items: center;
  background-color: #ffffff;
  color: #2c3e50; /* Dark blue */
  padding: 20px; /* Increased padding for better overall spacing */
  box-sizing: border-box;
  margin-top: 100px;
}

.service-detail-header {
  text-align: center;
  margin-bottom: 10px; /* Increased space below header */
}

.service-image {
  max-width: 50%; /* Reduce from 100% to 80% for a smaller image */
  height: 50%;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  margin-bottom: 20px;
}

.service-title {
  font-size: 2.5rem;
  margin-top: 20px;
  margin-bottom: 10px;
  color: #5d3f8d; /* Purple */
  text-shadow: none;
}

.service-detail-content {
  max-width: 900px;
  width: 100%; /* Ensure full width within max-width constraint */
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 12px;
  padding: 30px; /* Increased padding for better internal spacing */
  box-shadow: 0 8px 18px rgba(0, 0, 0, 0.3);
  color: #4e3b31; /* Dark brown */
  margin-bottom: 30px;
}

.service-description section {
  margin-bottom: 40px; /* Add space between sections */
}

.service-description h2 {
  color: #2c3e50; /* Dark blue */
  font-size: 1.8rem;
  margin-top: 0px; /* Increased space above h2 */
  margin-bottom: 10px; /* Increased space below h2 */
}

.service-description h3 {
  color: #5d3f8d; /* Purple */
  font-size: 1.5rem;
  margin-top: 25px; /* Increased space above h3 */
  margin-bottom: 15px; /* Increased space below h3 */
}

.service-description p {
  line-height: 1.6;
  font-size: 1.1rem;
  margin-bottom: 20px; /* Increased space below paragraphs */
  color: #4e3b31; /* Dark brown */
}

.service-description ul {
  line-height: 1.6;
  font-size: 1.1rem;
  margin-top: 10px; /* Space above lists */
  margin-bottom: 10px; /* Increased space below lists */
  padding-left: 25px; /* Slightly increased indentation for list items */
}

.service-description li {
  margin-bottom: 2px; /* Increased space between list items */
}

a {
  color: #0000EE;
  text-decoration: underline;
}

a:hover {
  color: #0066CC;
}

.service-detail-container {
  position: relative;
}

.service-detail-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.home-button {
  position: absolute;
  top: 20px;
  left: 20px;
  background: none;
  border: none;
  color: #2c3e50; /* Dark blue */
  font-size: 24px;
  cursor: pointer;
}

.home-button i {
  margin-right: 8px;
}

.service-image {
  width: 100%;
  max-width: 600px;
  margin: 20px 0;
}

.service-title {
  font-size: 2em;
  margin-bottom: 20px;
  color: #5d3f8d; /* Purple */
}

.service-detail-content {
  padding: 20px;
}

.back-home-button {
  display: block;
  margin: 20px auto;
  padding: 10px 20px;
  background-color: #000; /* Change based on your theme */
  color: #fff;
  border: none;
  cursor: pointer;
  font-size: 1em;
}

.service-detail-container {
  position: relative; /* To position the close icon */
  padding: 20px; /* Add some padding if needed */
}

.top-right-close-icon {
  position: absolute; /* Position it relative to the container */
  top: 20px; /* Adjust the top spacing */
  right: 20px; /* Adjust the right spacing */
  cursor: pointer; /* Change cursor to pointer on hover */
}

.service-image {
  width: 100%; /* Adjust to fit your layout */
  height: auto; /* Maintain aspect ratio */
}

.service-title {
  font-size: 2rem; /* Adjust title size */
  margin: 10px 0; /* Margin for spacing */
  color: #5d3f8d; /* Purple */
}

.service-detail-content {
  margin-top: 20px; /* Margin for spacing */
}

.bottom-home-button {
  margin-top: 20px; /* Margin for spacing */
  text-align: center; /* Center the button */
}

.back-home-btn {
  padding: 10px 20px; /* Padding for the button */
  font-size: 1rem; /* Font size for the button */
  cursor: pointer; /* Change cursor to pointer on hover */
}
