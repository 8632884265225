.container {
    padding: 20px;
    font-family: Arial, sans-serif;
    line-height: 1.6;
    margin-top: 100px;
  }
  
  .heading {
    font-size: 28px;
    color: #333;
    margin-bottom: 20px;
  }
  
  .subheading {
    font-size: 22px;
    color: #444;
    margin-bottom: 15px;
    margin-top: 30px;
  }
  
  .paragraph {
    font-size: 16px;
    color: #555;
    margin-bottom: 20px;
  }
  
  .four-image {
    width: 100%;
    height: auto;
    margin-bottom: 20px;
  }
  
  .back-button {
    display: block;
    margin: 20px auto; /* Centers the button */
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    text-decoration: none;
    border-radius: 5px;
    font-size: 16px;
    transition: background-color 0.3s ease;
}

.back-button:hover {
    background-color: #0056b3;
}
