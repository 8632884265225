/* DirectHire.css */

/* General Container Styling */
.direct-hire-container {
    max-width: 800px;
    margin: 80px auto;
    padding: 30px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
    line-height: 1.75;
    color: #333;
    background-color: #fff;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    border: 1px solid #e5e5e5;
}

/* Heading Styling */
.direct-hire-heading {
    font-size: 34px;
    color: #2c3e50;
    font-weight: 700;
    margin-bottom: 20px;
    text-align: center;
    margin-top: 20px;
}

/* Subheading Styling */
.direct-hire-subheading {
    font-size: 26px;
    color: #34495e;
    font-weight: 600;
    margin-top: 30px;
    margin-bottom: 15px;
}

/* Paragraph Styling */
.direct-hire-paragraph {
    font-size: 18px;
    color: #4f4f4f;
    margin-bottom: 20px;
    line-height: 1.85;
}

/* List Styling */
.direct-hire-list {
    margin: 20px 0;
    padding-left: 25px;
    color: #4f4f4f;
    list-style-type: disc;
}

.direct-hire-list li {
    margin-bottom: 12px;
    font-size: 17px;
}

/* Differentiators Heading */
.direct-hire-differentiators-heading {
    font-size: 22px;
    color: #34495e;
    margin-bottom: 15px;
}

/* Email Styling */
.direct-hire-email {
    color: #2980b9;
    text-decoration: none;
    font-weight: bold;
    margin-left: 5px;
    transition: color 0.3s ease;
}

.direct-hire-email:hover {
    color: #1c598a;
    text-decoration: underline;
}

/* Back Button Styling */
.direct-hire-back-button {
    display: inline-block;
    padding: 10px 20px;
    font-size: 16px;
    color: #fff;
    background-color: #2980b9;
    text-decoration: none;
    border-radius: 5px;
    margin-top: 20px;
    transition: background-color 0.3s ease;
}

.direct-hire-back-button:hover {
    background-color: #1c598a;
}

/* Responsive Design */
@media (max-width: 600px) {
    .direct-hire-container {
        padding: 20px;
        margin: 50px auto;
    }

    .direct-hire-heading {
        font-size: 28px;
    }

    .direct-hire-subheading {
        font-size: 22px;
    }

    .direct-hire-paragraph, .direct-hire-contact {
        font-size: 16px;
    }

    .direct-hire-list li {
        font-size: 16px;
    }
}
/* Back Button Container */
.direct-hire-back-button-container {
    text-align: center;
    margin-top: 20px;
}

/* Back Button Styling */
.direct-hire-back-button {
    display: inline-block;
    padding: 10px 20px;
    font-size: 16px;
    color: #fff;
    background-color: #2980b9;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

.direct-hire-back-button:hover {
    background-color: #1c598a;
}
.direct-hire-container {
    position: relative; /* Ensures the close icon is relative to this container */
}

.close-icon {
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 24px;
    cursor: pointer;
    color: #333; /* Adjust color as needed */
}

.close-icon:hover {
    color: #ff0000; /* Example hover effect */
}
