.recruitment-app-container {
    width: 100%;
    padding: 20px;
    margin: 0 auto;
    max-width: 1200px;
    margin-top: 100px;
  }
  
  .four-image {
    width: 100%; /* Make the image responsive */
    max-width: 600px; /* Limit the maximum width of the image */
    height: auto; /* Maintain the aspect ratio */
    display: block;
    margin: 0 auto 20px; /* Center the image and add margin at the bottom */
  }
  
  .recruitment-app-title {
    font-size: 28px;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .recruitment-app-description,
  .section-description {
    font-size: 18px;
    margin-bottom: 15px;
    line-height: 1.6;
  }
  
  .section-title {
    font-size: 24px;
    margin-top: 30px;
    margin-bottom: 10px;
  }
  
  .back-button-container {
    margin-top: 30px;
    text-align: center;
  }
  
  .back-button {
    display: inline-block;
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    text-decoration: none;
    border-radius: 5px;
  }
  
  .back-button:hover {
    background-color: #0056b3;
  }
  