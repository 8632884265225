.client-containers {
    padding: 20px;
    background-color: #f4f4f9;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 1200px;
    margin: 0 auto;

      /* existing styles */
      text-align: center; /* This will center any inline-block elements like the button */
      margin-top: 80px;
  }
  
  
  
  .client-banner-images {
    width: 100%;
    height: auto;
    border-radius: 10px;
    margin-bottom: 20px;
  }
  
  .client-titles {
    font-size: 2.5em;
    color: #333;
    text-align: center;
    margin-bottom: 15px;
  }
  
  .client-descriptions {
    font-size: 1.2em;
    color: #555;
    line-height: 1.6;
    text-align: center;
    margin-bottom: 30px;
  }
  
  .client-logoss {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
    gap: 15px;
    margin-bottom: 30px;
  }
  
  .client-logos {
    width: 100%;
    height: auto;
    object-fit: contain;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }
  
  .client-list {
    list-style-type: none;
    padding-left: 0;
    text-align: center;
  }
  
  .client-item {
    font-size: 1.1em;
    color: #007bff;
    margin-bottom: 10px;
  }
  
  .back-button {
    display: inline-block;
    background-color: #007bff;
    color: #fff;
    padding: 10px 20px;
    border-radius: 5px;
    text-decoration: none;
    text-align: center;
    margin-top: 20px;
    transition: background-color 0.3s ease;
  }
  
  .back-button:hover {
    background-color: #0056b3;
  }
  .back-button {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    text-decoration: none;
    font-size: 16px;
    border-radius: 5px;
    display: inline-block;
    text-align: center;
    align-self: center; /* This will center the button */
  }