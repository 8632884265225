.end {
    background-color: #333;
    padding: 20px;
    text-align: center;
    color: #fff;
    font-size: 0.9rem;
  }
  
  .last-text pre {
    margin: 0;
    font-family: inherit;
    color: #ccc;
  }
  
  /* Styling the container for social media icons */
  .con {
    margin-top: 10px;
  }
  
  /* Styling for the social media icons */
  .con a {
    margin: 0 15px;
    color: #fff;
    transition: color 0.3s ease;  /* Smooth transition for hover effect */
  }
  
  .con a:hover {
    color: #1da1f2;  /* Change this color to a desired hover color */
  }
  
  /* Specific colors for each social media icon */
  .con a:nth-child(1) {
    color: #0e76a8;  /* LinkedIn original color */
  }
  
  .con a:nth-child(1):hover {
    color: #0072b1;  /* LinkedIn hover color */
  }
  
  .con a:nth-child(2) {
    color: #e4405f;  /* Instagram original color */
  }
  
  .con a:nth-child(2):hover {
    color: #c13584;  /* Instagram hover color */
  }
  
  /* Responsive design for smaller screens */
  @media (max-width: 768px) {
    .con a {
      margin: 0 10px;
    }
  
    .end {
      font-size: 0.8rem;
    }
  }
  